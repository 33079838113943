<template>
  <section>
    <Input
      v-model="quiz.title"
      @blur="$v.quiz.title.$touch()"
      :error="$v.quiz.title.$error"
      id="quiz_title"
      :label="$t('quiz.title')"
      :placeholder="$t('quiz.title_placeholder')"
      :disabled="disabled"
    />
    <Select
      v-model="quiz.category"
      :options="categories"
      id="quiz_categories"
      @blur="$v.quiz.category.$touch()"
      :error="$v.quiz.category.$error"
      :label="$t('quiz.categories')"
      :disabled="disabled"
    />
    <Textarea
      v-model="quiz.description"
      id="quiz_description"
      :label="$t('quiz.description')"
      :disabled="disabled"
      :rows=3
    />
    <div class="published-dates">
      <Datepicker
        class="published-dates__datepicker"
        v-model="quiz.publishedSince"
        id="quiz_publishedSince"
        :label="$t('quiz.published_since')"
        :placeholder="$t('quiz.select_date')"
        :show-set-now="!disabled"
        :disabled="disabled"
      />
      <Datepicker
        class="published-dates__datepicker"
        v-model="quiz.publishedUntil"
        id="quiz_publishedUntil"
        :label="$t('quiz.published_until')"
        :placeholder="$t('quiz.select_date')"
        :disabled="disabled"
      />
    </div>
  </section>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import QuizMixin from '@/components/mixins/QuizMixin'

export default {
  name: 'QuizOneQuestionFormMainTabLeftColumn',
  mixins: [QuizMixin],
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Select,
    Textarea,
    Input,
    Datepicker
  },
  validations: {
    quiz: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      category: {
        required
      }
    }
  },
  data () {
    return {
      categories: []
    }
  },
  methods: {
    async loadCategories () {
      this.categories = await this.getOneQuestionCategories()
    }
  },
  created () {
    this.loadCategories()
  }
}
</script>
<style scoped lang="scss">
.published-dates {
  display: flex;
  gap: 2%;
  &__datepicker {
    flex: 0 0 49%;
  }
}
</style>
