<template>
  <section>
    <ModuleFormButton
      icon="delete"
      :disabled="disabled"
      :medium="medium"
      :required-permissions="requiredPermissions"
      @click="showDeleteModal"
    />
    <Modal
      v-if="deleteModal"
      modal-size="modal-md"
      :title="$t('modal.delete_modal_header')"
      @close="closeDeleteModal"
    >
      <template slot="body">
        {{ $t('modal.delete_modal_text') }}
      </template>

      <template slot="footer">
        <button
          type="button"
          class="btn btn-inverse router-link-active"
          @click="closeDeleteModal"
        >
          {{ $t('modal.delete_modal_cancel_btn') }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="deleteRecord"
        >
          {{ $t('modal.delete_modal_confirm_btn') }}
        </button>
      </template>
    </Modal>
  </section>
</template>

<script>
import Modal from '@/components/shared/Modal'
import { CANNOT_DELETE_IN_USE } from '@/model/ValueObject/ErrorCodes'
import NotifyService from '@/services/NotifyService'
import ModuleFormButton from '@/components/shared/ModuleFormButton'

export default {
  name: 'ModuleFormDeleteButton',
  props: {
    storeName: {
      type: String,
      required: true
    },
    listRouterPath: {
      type: String,
      default: ''
    },
    recordId: {
      type: [Number, String],
      required: true
    },
    actionIdParam: {
      type: String,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    requiredPermissions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      deleteModal: false
    }
  },
  components: {
    ModuleFormButton,
    Modal
  },
  methods: {
    showDeleteModal () {
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    deleteRecord () {
      this.$store.dispatch(`${this.storeName}/deleteRecord`, { [this.actionIdParam]: this.recordId })
        .then(() => {
          const error = this.$store.getters[`${this.storeName}/error`]
          if (error === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            if (this.listRouterPath) {
              this.$router.push(`/${this.listRouterPath}`)
            }
            this.$emit('delete-record')
            this.closeDeleteModal()
          } else {
            if (error === CANNOT_DELETE_IN_USE || error?.response?.data?.error === CANNOT_DELETE_IN_USE) {
              // setErrorMessage() is already in src/api/core.js interceptor
              this.closeDeleteModal()
            } else {
              NotifyService.setErrorMessage(error)
            }
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>
