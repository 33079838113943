<script>
import OneQuestionNewView from './QuizOneQuestionNewView'

export default {
  name: 'OneQuestionEditView',
  extends: OneQuestionNewView,
  methods: {
  },
  mounted () {
    const previousTabIndex = this.$store.getters['quiz/previousTabIndex']
    this.$refs.quizForm.tabClick(previousTabIndex)
    this.$store.commit('quiz/setPreviousTabIndex', 0)
    this.getQuiz()
  }
}
</script>
