export default {
  id: null,
  title: '',
  description: '',
  image: null,
  imageInfo: null,
  position: 0,
  points: 0,
  voted: 0
}
