export default {
  id: null,
  title: '',
  description: '',
  image: null,
  imageInfo: null,
  position: 0,
  articleId: null,
  articleTitle: '', // only in GET
  answers: []
}
