<template>
  <div>
    <div
      v-for="(question, qIndex) in quiz.questions"
      :key="`question-${qIndex}`"
    >
      <Input
        v-model="question.title"
        :id="`question_title-${qIndex}`"
        @blur="$v.quiz.questions.$each[qIndex].title.$touch()"
        :error="$v.quiz.questions.$each[qIndex].title.$error"
        :label="$t('quiz.question_title')"
        :disabled="disabled"
      />
      <Textarea
        v-model="question.description"
        :id="`question_description-${qIndex}`"
        :label="$t('quiz.question_description')"
        :rows=2
        :disabled="disabled"
      />
      <div class="title-text">
        {{ $t('quiz.question_image') }}
      </div>
      <ModuleFormPhotoBox
        :image="question.imageInfo"
        :disabled="disabled"
        :media-usage-type="mediaUsageTypes.question"
        @set-media="(medias) => setImage(question, medias)"
        @remove-media="removeImage(question)"
        buttons-only
      />
      <div v-if="!$v.quiz.questions.$each[qIndex].answers.required || !$v.quiz.questions.$each[qIndex].answers.minLength"
           class="error-text">
        {{ $t('quiz.enter_minimum_two_answers') }}
      </div>
      <div
        v-for="(answer, aIndex) in question.answers"
        :key="`question-${qIndex}-answer-${aIndex}`"
        class="answers"
      >
        <div class="answers__heading">
          <div class="answers__heading__text">
            {{ $t('quiz.answer_number', { number: aIndex + 1 }) }}
          </div>
          <ModuleFormButton
            v-if="!disabled"
            icon="delete"
            @click="removeAnswer(question, aIndex)"
          />
        </div>
        <div class="answers__inputs">
          <Input
            v-model="answer.title"
            :id="`question-${qIndex}-answer_title-${aIndex}`"
            @blur="$v.quiz.questions.$each[qIndex].answers.$each[aIndex].title.$touch()"
            :error="$v.quiz.questions.$each[qIndex].answers.$each[aIndex].title.$error"
            :label="$t('quiz.answer_title')"
            :disabled="disabled"
            class="answers__inputs__title"
          />
          <Input
            v-model="answer.points"
            :id="`question-${qIndex}-answer_points-${aIndex}`"
            :label="$t('quiz.answer_points')"
            type="number"
            :disabled="disabled"
            @change="$emit('answer-points-change')"
            class="answers__inputs__points"
          />
        </div>
        <div class="title-text">
          {{ $t('quiz.answer_image') }}
        </div>
        <ModuleFormPhotoBox
          :image="answer.imageInfo"
          :disabled="disabled"
          :media-usage-type="mediaUsageTypes.answer"
          @set-media="(medias) => setImage(answer, medias)"
          @remove-media="removeImage(answer)"
          buttons-only
        />
      </div>
      <button
        v-if="!disabled"
        class="add-button "
        :title="$t('quiz.add_answer')"
        @click="addAnswer(question)"
      >
        <PlusIcon class="add-button__icon" />
        {{ $t('quiz.add_answer') }}
      </button>
    </div>
  </div>
</template>

<script>
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizAnswerModel from '@/model/QuizAnswerModel'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import { MEDIA_USAGE_TYPE_QUIZ_ANSWER, MEDIA_USAGE_TYPE_QUIZ_QUESTION } from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'QuizOneQuestionFormQuestionTab',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormButton,
    ModuleFormPhotoBox,
    Input,
    Textarea,
    PlusIcon
  },
  data () {
    return {
      mediaUsageTypes: {
        question: MEDIA_USAGE_TYPE_QUIZ_QUESTION,
        answer: MEDIA_USAGE_TYPE_QUIZ_ANSWER
      }
    }
  },
  validations: {
    quiz: {
      questions: {
        $each: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(250)
          },
          answers: {
            required,
            minLength: minLength(2),
            $each: {
              title: {
                required,
                minLength: minLength(1)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    setImage (questionOrAnswer, medias) {
      medias.forEach(media => {
        questionOrAnswer.imageInfo = media
        questionOrAnswer.image = media.id
      })
    },
    removeImage (questionOrAnswer) {
      questionOrAnswer.image = null
      questionOrAnswer.imageInfo = null
    },
    addAnswer (question) {
      question.answers.push(this._.cloneDeep(QuizAnswerModel))
    },
    removeAnswer (question, index) {
      question.answers.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.error-text {
  color: #dc3545;
  font-size: rem(14px);
  margin-top: rem(10px);
}
.add-button {
   @include font(700 13px "Roboto");
   @include padding(7px 14px);
   @include radius(4px);
   margin-top: 1rem;
   height: rem(30px);
   cursor: pointer;
   background: #6599fe;
   color: #fff;
   border: none;
   transition: background 200ms;
   display: flex;
   justify-content: center;
   align-items: center;
   @include bp(10) {
     &:hover {
       background: darken(#6599fe, 10%);
     }
   }
   &__icon {
     @include size(rem(14px));
     fill: #FFFFFF;
     float: left;
     margin-right: rem(8px);
   }
 }
.answers {
  background-color: #FFFFFF;
  margin-top: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);
  &__heading {
    display: flex;
    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(400 16px "Roboto");
      color: #465674;
      margin-bottom: rem(8px);
    }
  }
  &__inputs {
    display: flex;
    gap: 2%;
    &__title {
      flex: 0 0 74%;
    }
    &__points {
      flex: 0 0 24%;
    }
  }
}

</style>
